.resources-page-container {
  display: flex;
  flex-direction: column; /* Align text and image in a row */
  width: 70%;
  background-color: #ffffff; /* Optional: Add background color */
  margin: auto;
  margin-top: 30px;
}

.resources-content {
  flex: 2;
  padding-right: 20px;
}

.resources-content h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: rgb(0, 84, 147);
}

.resources-content h3 {
  text-align: left;
  font-size: 1.2rem; /* Adjust as needed */
  margin-bottom: 20px;
  color: rgb(0, 84, 147);
}

.resources-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
}

.resources-content a {
  color: #0088cc;
  outline: none;
  text-decoration: none;
}

.resources-content a:hover {
  text-decoration: underline;
}

/* List Styling */
ol, ul {
  margin-left: 30px; /* Indent lists */
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

ol {
  list-style-type: decimal; /* Standard ordered list numbers */
}

ul {
  list-style-type: disc; /* Standard unordered list bullets */
}

li {
  margin-bottom: 10px; /* Add spacing between list items */
}

li::marker {
  font-size: 1.5rem;
  color: rgb(0, 84, 147); /* Custom bullet color */
}