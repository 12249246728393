.collaborate-container {
    display: flex;
    flex-direction: column; /* Align text and image in a row */
    width: 70%;
    background-color: #ffffff; /* Optional: Add background color */
    margin: auto;
    margin-top: 30px;
}
  
  
.collaborate-card {
    flex: 2;
    padding-right: 20px; /* Space between text and image */
}

.collaborate-card h1 {
    text-align: center;
    font-size: 2rem; /* Adjust as needed */
    margin-bottom: 20px;
    color: rgb(0, 84, 147);
}

.collaborate-card p {
    font-size: 1rem; /* Adjust as needed */
    margin-bottom: 15px;
    text-align: left;
}

.collaborate-card ul li {
    font-size: 1rem; /* Adjust as needed */
    margin-bottom: 15px;
    text-align: left;
}

.collaborate-card p1 {
    font-size: 1.2rem; /* Adjust as needed */
    margin-bottom: 20px;
    font-style: italic;
    color: black;
    text-align: left;
}

.collaborate-card p2 {
  font-size: 1.3rem; /* Adjust as needed */
  margin-bottom: 20px;
  color: black;
  text-align: left;
}

  